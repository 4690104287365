<template>
  <div class="help">
    Эта страница будет содержать информацию по всем приложениям
  </div>
</template>

<script>
export default {
  name: "Help"
}
</script>